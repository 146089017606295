import React, { useEffect, useState, useRef } from "react";
import ReactPlayer from "react-player";
import styles from "./featured-home.module.css";
import NavBar2 from "../NavBar2/NavBar2";
import Basket from "../Cart/Basket";
import StorageManager from "../../services/frontend/StorageManager";
import { CART_KEY } from "../../services/frontend/StorageKeys";
import { initialCart } from "../../services/ObjectsInitialValues"
import useLocalStorage from "../../hooks/useLocalStorage";

const FeaturedHome = ({updateHomeActive, heroMedia, heroTitle}) => {
  const [cart, setCart] = useLocalStorage(CART_KEY, initialCart);
  const [showCart, setShowCart] = useState(false);
  const handleShowCart = (val = false) => {
    setShowCart(val);
  };
  return (
    <div>
      <div className={styles.hero}>
      <Basket
        setRefresh={()=> {}}
        isBasketOpen={showCart}
        setCart={setCart}
        cartData={cart}
        StorageManager={StorageManager}
        CART_KEY={CART_KEY}
        handleShowCart={handleShowCart}/>
      <NavBar2
        cartActive= {true}
        hideInAdvance={false}
        homeActive= {true}            
        storeName={heroTitle}
        handleShowCart={handleShowCart}
        />
        <ReactPlayer
          url={heroMedia}
          playing
          loop
          muted
          playsinline
          width="100%"
          height="100%"
          className={styles.backgroundVideo}
        />
      </div>
      <div className={styles.homeDetailsContainer}>
        <div
          onClick={() => updateHomeActive(false)}
          className={styles.homeDetailsButton}
        >
          {"SHOP NOW"}
        </div>
      </div>
    </div>
  );
};

export default FeaturedHome;
