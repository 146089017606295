import { useState, useEffect } from 'react';
import { publicServerEndpoint } from '../services/helper';

const useCollection = (storeId) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
       const fetchCollection = async () => {
        try {
            const response = await fetch(`${publicServerEndpoint}/product/store-collection/${storeId}`)
            if (!response.ok) {
                throw {
                  code: response.status,
                  message: response.status
                }
              }
            const responseJson = await response.json();
            setData(responseJson.data)
        } catch(e) {
            console.log("Error occured while calling store api", e)
            setError({
                code: e.code || 500,
                message: e.message || "Something went wrong"
            })
        } finally {
            setIsLoading(false)
        }
        
       }
       fetchCollection()
    }, []);

    return { data, isLoading, error };
};

export default useCollection;
