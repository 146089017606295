import React, { useCallback, useMemo, useRef, useState } from 'react';
import NavBar from '../Navbar';
import useLocalStorage from '../../hooks/useLocalStorage';
import { CART_KEY } from '../../services/frontend/StorageKeys';
import { initialCart } from '../../services/ObjectsInitialValues';
import Basket from '../Cart/Basket';
import StorageManager from '../../services/frontend/StorageManager';
import useCollection from '../../hooks/useCollection';
import useStoreProducts from '../../hooks/useStoreProducts';
import LoaderComponent from '../Loader';
import StoreCollections from '../StoreCollections';
import StoreItem from '../StoreItem';
import EmptyStore from '../EmptyStore';
import Error500Page from '../../pages/500';
import Loader from 'react-loader-spinner';
import styles from "./collection-home.module.css";

export const CollectionHome = ({ store }) => {
  const { data: collections, isLoading: isCollectionLoading, error: collectionError } = useCollection(store.id);
  const [selectedCollection, setSelectedCollection] = useState("all products");
  const [triggerNextPage, setIsTriggeredNextPage] = useState(1);
  const { data: products, isLoading: isProductLoading, error: productError, collectionHasNextPage } = useStoreProducts(store.id, selectedCollection, triggerNextPage);

  const [cart, setCart] = useLocalStorage(CART_KEY, initialCart);
  const [showCart, setShowCart] = useState(false);
  const [collectionsHeight, setCollectionsHeight] = useState(0);
  const selectedProducts = useMemo(() => {
    return products.get(selectedCollection)
  }, [products, selectedCollection]);
  const hasNextPage = useMemo(() => {
    return collectionHasNextPage.get(selectedCollection) || false
  }, [collectionHasNextPage, selectedCollection]);
  const observer = useRef();
  const lastProductRef = useCallback(node => {
    if (isProductLoading) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasNextPage) {
        setIsTriggeredNextPage(prev => !prev);
      }
    });

    if (node) observer.current.observe(node);
  }, [isProductLoading, hasNextPage]);

  const handleShowCart = useCallback((boolVal = false) => {
    setShowCart(boolVal);
  }, []);

  const handleCollectionHeight = useCallback((collectionHeight) => {
    setCollectionsHeight(collectionHeight);
  }, []);

  const setFilter = useCallback((collection) => {
    setSelectedCollection(collection);
  }, []);

  if (isCollectionLoading) return <LoaderComponent />;
  if (collectionError || productError) return <Error500Page />;

  return (
    <div className={styles.store}>
      <Basket
        setRefresh={() => { }}
        isBasketOpen={showCart}
        setCart={setCart}
        cartData={cart}
        StorageManager={StorageManager}
        CART_KEY={CART_KEY}
        handleShowCart={handleShowCart}
      />
      <NavBar
        cartActive={cart.products.length > 0}
        handleShowCart={handleShowCart}
        hideInAdvance={false}
        homeActive={false}
        store={store}
        storeName={store?.storeName || ''}
      />
      {
        collections && collections.length > 0 && (
          <StoreCollections
            collections={collections}
            setCollectionsHeight={handleCollectionHeight}
            setFilter={setFilter}
          />
        )
      }

      {!selectedProducts || (selectedProducts.length === 0 && isProductLoading) ? (
        <div className={styles.firstTimeCollectionProductLoader}>
          <Loader
            type="Oval"
            color="#ccc"
            height={40}
            width={40}
          />
        </div>)
        :
        (
          <div
            className={selectedProducts.length > 0 ? styles.storeItems : ''}
            style={{ paddingTop: `${collectionsHeight + 16}px` }}
          >
            {selectedProducts.length > 0 ? (
              selectedProducts.map((product, index) => {
                if (selectedProducts.length === index + 1) {
                  return (
                    <div ref={lastProductRef} key={product.id}>
                      <StoreItem product={product} />
                    </div>
                  );
                }
                return <StoreItem product={product} key={product.id} />;
              })
            ) : (
              <EmptyStore store={store} />
            )}
          </div>
        )
      }
      {selectedProducts && selectedProducts.length !== 0 && isProductLoading && (
        <div className={styles.productLoader}>
          <Loader
            type="Oval"
            color="#ccc"
            height={40}
            width={40}
          />
        </div>
      )}
    </div>
  );
};
