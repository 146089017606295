import React, { useMemo, useState } from "react";
import { CollectionHome } from "./CollectionHome";
import styles from "./main.module.css";
import FeaturedHome from "./FeaturedHome";
import { useRouter } from "next/router";
import Footer from "../Footer"
export const MainHome = ({ store, hideHeroMedia }) => {
  const homePageEnabled = store?.homePageEnabled;
  const homeActive = useMemo(()=> {
    return homePageEnabled && homePageEnabled === true ? (hideHeroMedia ? false : true) : false
  },[hideHeroMedia,homePageEnabled]);
  const router = useRouter()

  const updateHomeActive = (boolVal = false) => {
    router.push('/shop')
  }

  return (
    <main
      className={styles.main}
      style={{
        transition: "max-height 0.2s",
      }}
    >
      {homeActive ? (
        <FeaturedHome
          updateHomeActive={updateHomeActive}
          heroMedia={store?.homePage?.heroMedia.url}
          heroTitle={store.homePage.heroTitle}
        />
      ) : (
        <CollectionHome store={store} />
      )}
      <Footer />
    </main>
  );
};
