import { useState, useEffect, useRef } from 'react';
import { publicServerEndpoint } from '../services/helper';
import { useIsFirstRender } from './isFirstRender';

const useStoreProducts = (storeId, collection, hasNextPageTriggered) => {
    const [data, setData] = useState(new Map([
        ["all products", []]
    ]));
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [collectionHasNextPage, setCollectionHasNextPage] = useState(new Map([
        ["all products", false]
    ]));
    const pageRef = useRef(new Map([
        ["all products", 1]
    ]))
    const isFirstRender = useIsFirstRender()
    const fetchProducts = async (collection, pageNumber) => {
        try {
            setIsLoading(true);

            let response;
            if (collection && collection !== "all products") {
                response = await fetch(`${publicServerEndpoint}/product/store/${storeId}?collection=${collection}&page=${pageNumber}&limit=20&source=web`);
            } else {
                response = await fetch(`${publicServerEndpoint}/product/store/${storeId}?page=${pageNumber}&limit=20&source=web`);
            }

            if (!response.ok) {
                throw {
                    code: response.status,
                    message: response.statusText || "Error"
                };
            }

            const responseJson = await response.json();
            const responseData = responseJson.data;
            setData(prevData => {
                const currentCollection = collection || "all products";
                const existingList = prevData.get(currentCollection) || []
                const existingIds = new Set(existingList.map(item => item.id));
                const uniqueDocs = responseData.docs.filter(doc => !existingIds.has(doc.id));
                prevData.set(currentCollection, [...existingList, ...uniqueDocs]);
                return new Map(prevData);
            });
            setCollectionHasNextPage(prevData => {
                const currentCollection = collection || "all products";
                prevData.set(currentCollection, responseData.hasNextPage || false);
                return new Map(prevData);
            });
            if (!pageRef.current.get(collection)) {
                pageRef.current.set(collection, 1)
            }
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            console.log("Error occurred while calling store API", e);
            setError({
                code: e.code || 500,
                message: e.message || "Something went wrong"
            });
        }
    };
    useEffect(() => {
        if (data.get(collection) && data.get(collection).length > 0) return
        fetchProducts(collection, 1);
    }, [collection]);

    useEffect(() => {
        if (isFirstRender) return
        const currentValue = pageRef.current.get(collection)
        pageRef.current.set(collection, currentValue + 1)
        fetchProducts(collection, currentValue + 1);
    }, [hasNextPageTriggered]);

    return { data, isLoading, error, collectionHasNextPage };
};

export default useStoreProducts;
